.App {
  text-align: center;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */

  color: #000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
