body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.traced-header {
  height: 30px;
  width: 100vw;
  background-image: linear-gradient(90deg, #33CC66 33.33%, #FFCC00 33.33%, #FFCC00 66.66%, #008FFE 66.66%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0px;
}

.App-logo {
  width: 183px;
  height: 200px;
  margin-top: 40px;
}

.videoFrame {
  max-width: 100% !important;
  max-height: 60vh !important;
  width: 1000px;
  height: 563px;
}

/* width="560" height="315" */

.CheckoutBox {
  border: 1px solid #D0D5DD;
  border-radius: 10px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  max-width: 880px;
  margin: 20px auto;
  position: relative;
  text-align: left;

  @media(max-width: 800px) {
    border: 0;
  }
}

.CheckoutBox h3 {
  background: #F9FAFB;
  padding: 8px 16px;
  font-size: 20px;
  border-radius: 5px;
}